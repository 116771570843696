/**
 *
 * Color styles
 *
 */

$colorWhite: #fff;
$colorBlack: #333;
$colorBlueDark: #183671;
$colorBlue: #2c65ae;
$colorBlueLight: #066cc2;
$colorBlueLight2: #eaf4fc;
$colorGrey: #fcfcfc;
$colorGreyDarker: #c5c5c5;
$colorGreyDark: #979797;
$colorRed: #db0000;
$colorRadio: #f5fafd;
$colorRadioBorder: rgba(197, 197, 197, 0.2);
$colorRadioShadow: rgba(0, 0, 0, 0.33);
