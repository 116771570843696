@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSans/CallunaSans-BoldItalic.eot');
  src: url('../fonts/CallunaSans/CallunaSans-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/CallunaSans/CallunaSans-BoldItalic.woff2') format('woff2'), url('../fonts/CallunaSans/CallunaSans-BoldItalic.woff') format('woff'), url('../fonts/CallunaSans/CallunaSans-BoldItalic.ttf') format('truetype'), url('../fonts/CallunaSans/CallunaSans-BoldItalic.svg#CallunaSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSans/CallunaSans-Regular.eot');
  src: url('../fonts/CallunaSans/CallunaSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/CallunaSans/CallunaSans-Regular.woff2') format('woff2'), url('../fonts/CallunaSans/CallunaSans-Regular.woff') format('woff'), url('../fonts/CallunaSans/CallunaSans-Regular.ttf') format('truetype'), url('../fonts/CallunaSans/CallunaSans-Regular.svg#CallunaSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSans/CallunaSans-Italic.eot');
  src: url('../fonts/CallunaSans/CallunaSans-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/CallunaSans/CallunaSans-Italic.woff2') format('woff2'), url('../fonts/CallunaSans/CallunaSans-Italic.woff') format('woff'), url('../fonts/CallunaSans/CallunaSans-Italic.ttf') format('truetype'), url('../fonts/CallunaSans/CallunaSans-Italic.svg#CallunaSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSans/CallunaSans-Bold.eot');
  src: url('../fonts/CallunaSans/CallunaSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/CallunaSans/CallunaSans-Bold.woff2') format('woff2'), url('../fonts/CallunaSans/CallunaSans-Bold.woff') format('woff'), url('../fonts/CallunaSans/CallunaSans-Bold.ttf') format('truetype'), url('../fonts/CallunaSans/CallunaSans-Bold.svg#CallunaSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
