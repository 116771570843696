/**
 *
 * Typography styles
 *
 */

$systemFonts: "Calluna Sans", -apple-system, blinkmacsystemfont, arial, helvetica, sans-serif;

// Font weights
$light: 300;
$regular: 400;
$semiBold: 600;
$bold: 700;

.light {
  font-weight: $light;
}

.regular {
  font-weight: $regular;
}

strong,
.semi-bold {
  font-weight: $semiBold;
}

.bold {
  font-weight: $bold;
}

// Headings
h1 {
  font-size: 30px;
  font-weight: 700;
  font-style: italic;
  line-height: 32px;
  color: $colorBlueDark;
  margin: 0 0 40px;
  text-align: center;
  position: relative;
  z-index: 2;
  background: #0075a3;
  background: -webkit-linear-gradient(left right, #0075a3 0%, #24315a 100%);
  background: -moz-linear-gradient(left right, #0075a3 0%, #24315a 100%);
  background: linear-gradient(to right, #0075a3 0%, #24315a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-rendering: optimizeLegibility;

  @include breakpoint(large) {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 56px;
  }
}

h2 {
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
  color: $colorBlueDark;
  margin: 0 0 14px;
  text-rendering: optimizeLegibility;
}

.lead {
  display: flex;
  line-height: 24px;
  color: $colorBlueDark;
  margin: 0 0 40px;
  text-rendering: optimizeLegibility;
  font-size: 20px;
  letter-spacing: -0.42px;
  align-items: baseline;

  span.round {
    display: inline-block;
    margin-right: 15px;
    text-align: center;
    color: $colorWhite;
    width: 55px;
    height: 35px;
    background: url("../images/blue-circle.svg") 0 0 no-repeat;
    background-position: center center;
    font-size: 22px;
    letter-spacing: -0.46px;
    line-height: 27px;
    padding: 3px 0 0 2px;

    @include breakpoint(large) {
      width: 35px;
    }

    &.second {
      width: 85px;

      @include breakpoint(large) {
        width: 35px;
      }
    }
  }
}

.small {
  font-size: 15px;
  margin-bottom: 40px;
  text-align: center;
}

.or {
  font-size: 15px;
  line-height: 16px;
  margin: 20px auto 8px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    height: 1px;
    width: 128px;
    border: 1px solid #183671;
    opacity: 0.2;
    margin: 0 23px;
  }
}
