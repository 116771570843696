/**
 *
 * Base styles
 *
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  background: $colorWhite;
  color: $colorBlack;
  font-family: $systemFonts;
  font-size: 100%;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;

  &::before {
    content: "";
    background-image: linear-gradient(180deg, #f9f8f8 0%, #d3eafb 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1828px;
  }

  @include breakpoint(large) {
    &::before {
      background: url("../images/bg-desktop@2x.png") 0 0 no-repeat;
      background-size: 100%;
      width: 2560px;
      height: 1300px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

main {
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  z-index: 2;
  flex: 1 0 auto;
}

// Links
a {
  color: $colorBlueLight;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.inner-container {
  max-width: 650px;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  position: relative;
  overflow: hidden;

  @include breakpoint(large) {
    overflow: unset;
  }

  &__title {
    max-width: 210px;
    position: relative;
    z-index: 2;
  }

  &__icecream {
    max-width: 210px;
    margin-bottom: 12px;
    position: relative;
    z-index: 2;
  }

  &__logo {
    position: relative;
    z-index: 2;
    text-align: center;
    margin-top: 12px;

    @include breakpoint(large) {
      text-align: left;
    }

    img {
      max-width: 85px !important;

      @include breakpoint(large) {
        max-width: 100% !important;
      }
    }
  }

  img {
    max-width: 100%;
  }

  &::before {
    display: none;

    @include breakpoint(large) {
      display: block;
      content: "";
      background-image: linear-gradient(180deg, #f9f8f8 34%, rgba(151, 203, 242, 0.67) 100%);
      height: 680px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &::after {
    content: "";
    width: 1062px;
    height: 724px;
    position: absolute;
    top: -300px;
    left: 50%;
    transform: translate(-50%, 0);
    background: url("../images/bg-mobile@2x.png") 0 0 no-repeat;
  }

  @include breakpoint(large) {
    flex-direction: row;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    align-items: flex-start;
    padding-top: 40px;
    margin-bottom: 40px;

    &__logo {
      width: 25%;
      padding: 0 20px;
    }

    &__title {
      width: 33.33%;
      max-width: none;
      padding: 0 20px;
    }

    &__icecream {
      width: 42.67%;
      max-width: none;
      padding: 0 20px;
    }

    &::before {
      content: none;
    }

    &::after {
      background: url("../images/bg-header@2x.png") 0 0 no-repeat;
      background-size: 100%;
      width: 909px;
      height: 773px;
      left: -30px;
      top: 0;
      transform: translate(0, 0);
    }
  }
}

.blue-button {
  cursor: pointer;
  background-image: linear-gradient(180deg, #2b7cd7 0%, #2060b0 100%);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: $colorWhite;
  border: 0;
  padding: 13px 15px 16px;
  max-width: 100%;
  width: 100%;
  margin-top: 16px;
  display: inline-block;

  @include breakpoint(large) {
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.prizes {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 2;

  li {
    margin-bottom: 36px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    color: $colorBlueDark;
    text-align: center;
    font-weight: 700;
    margin: 0;
  }
}

.explanation {
  padding-bottom: 50px;

  @include breakpoint(large) {
    padding-bottom: 0;
  }

  p {
    font-size: 22px;
    letter-spacing: -0.46px;
    line-height: 27px;
    color: $colorBlueDark;
    text-align: center;
    margin: 0 0 40px;
  }

  &__title {
    font-size: 22px;
    line-height: 27px;
    font-weight: 700;
    position: relative;
    margin: 0 0 64px;

    &::after {
      content: "";
      width: 188px;
      height: 9px;
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translate(-50%, 0);
      background: url("../images/below-date.svg") 0 0 no-repeat;
    }
  }

  &__text {
    padding-right: 20px;
  }

  @include breakpoint(large) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      text-align: left;
    }

    &__title {
      width: 400px;
      text-align: right !important;

      &::after {
        left: auto;
        right: 0;
        transform: translate(0, 0);
      }
    }
  }
}

.rules {
  margin: 32px 0 70px;

  .js-open-rules {
    font-size: 18px;
    font-weight: 700;
    color: $colorBlue;
    cursor: pointer;
    margin: 0 0 12px;

    img {
      margin-left: 3px;
      transform: rotate(0);
      transition: transform 0.25s ease;
    }

    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .js-rules {
    display: none;

    &.content-active {
      display: block;
    }
  }
}

.hidden {
  display: none !important;
}

.centered {
  text-align: center;
  padding-bottom: 187px;
}

body.finish {
  .main-container {
    &::before {
      top: -1297px;
      box-shadow: 0 2px 13px 0 rgb(0 0 0 / 19%);

      @include breakpoint(large) {
        top: -750px;
        box-shadow: none;
      }
    }
  }

  main {
    padding-bottom: 47px;

    @include breakpoint(large) {
      box-shadow: none;
    }
  }

  .content {
    margin-bottom: 40px;

    h2 {
      font-size: 30px;
      margin-bottom: 16px;
      margin-top: 110px;

      @include breakpoint(large) {
        font-size: 35px;
        margin-bottom: 8px;
      }
    }

    .big-check {
      width: 68px;
      margin-top: 38px;
      margin-bottom: 45px;

      @include breakpoint(large) {
        width: 80px;
      }
    }

    p {
      margin: 0;
      padding: 0;

      &.blue {
        color: $colorBlueDark;
        font-size: 20px;
        letter-spacing: -0.46px;
        line-height: 27px;
        margin-bottom: 40px;

        @include breakpoint(large) {
          font-size: 22px;
        }
      }

      strong {
        color: $colorBlack;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.33px;
        line-height: 19px;
      }
    }
  }
}

#download-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .main-container::before {
    background-image: none;
    width: 0;
    height: 0;
  }

  input {
    width: 100%;
  }

  .form-item {
    min-height: 94px;
    margin-bottom: 0;
  }
}

.row.with-padding {
  padding: 0 20px;

  @include breakpoint(large) {
    padding: 0 36px;
  }
}

.with-opacity {
  opacity: 0.5;
}

#cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $colorWhite;
  border-top: 5px solid $colorBlueLight;
  z-index: 1000;

  &.visible {
    display: block;
  }

  &.hidden {
    display: none;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .small {
    height: 36px;
    font-size: 16px;
    padding-top: 4px;
    width: 130px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    min-width: 130px;
    white-space: nowrap;

    @include breakpoint(medium) {
      margin-bottom: 5px;
    }
  }
}
