.footer {
  flex-shrink: 0;

  &__links {
    color: $colorBlue;
    background-color: $colorGrey;
    padding: 48px 0 50px;

    h3 {
      font-size: 22px;
      line-height: 27px;
      font-weight: 700;
      margin: 0 0 24px;
    }

    ul {
      margin: 0;
      padding: 0 0 24px;
      list-style: none;
    }

    li {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      line-height: 17px;
      color: $colorBlack;
      margin: 0 0 4px;
    }
  }

  &__link-extra {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin: 12px 0 0;
    display: block;
  }

  &__copy {
    width: 100%;
    height: 35px;
    background-color: $colorBlue;
    color: $colorWhite;
    padding: 0 20px;

    .inner-container {
      position: relative;
      max-width: 650px !important;

      img {
        width: 70px;
        height: auto;
        position: absolute;
        top: -26px;
        left: 0;
      }

      a {
        color: $colorWhite;
        text-align: right;
        font-size: 14px;
        letter-spacing: -0.29px;
        display: block;
        position: absolute;
        right: 0;
        top: 7px;
      }
    }
  }

  .inner-container {
    max-width: 690px;
  }
}
