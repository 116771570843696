/**
* Settings
*/
/* stylelint-disable no-descending-specificity */

$rs-cc-color-link: $colorBlue;
$rs-cc-color-background: $colorWhite;
$rs-cc-color-dark: $colorBlue;
$rs-cc-color-light: $colorBlack;
$rs-cc-padding: 80px;
$rs-cc-global-width: auto;
$rs-cc-font-size: 15px;
$rs-cc-line-height: 24px;
$rs-cc-font-family: "Calluna Sans", -apple-system, blinkmacsystemfont, arial, helvetica, sans-serif;

/**
*
* Utils
*
*/

.rs-cc--show {
  display: block;
}

.rs-cc--hide {
  display: none !important;
}

/**
* Styles
*/

.rs-cc {
  z-index: 200;
  width: 100%;
  bottom: 0;
  position: absolute;
  backface-visibility: hidden;
  font-size: 13px;
  line-height: $rs-cc-line-height;
  font-family: $rs-cc-font-family;

  @include breakpoint(medium) {
    font-size: $rs-cc-font-size;
  }

  &__step-container {
    max-width: $rs-cc-global-width;
    padding: 0 24px;
    margin: 0 auto;

    @include breakpoint(medium) {
      display: flex;
      justify-content: center;
      padding: 0 $rs-cc-padding;
    }

    &.-step-2 {
      font-family: $rs-cc-font-family;
      font-size: 16px;
      position: relative;

      .rs-cc__step-2 .rs-cc__close {
        top: 9px;
      }
    }
  }

  /**
  * texts
  */

  p {
    color: $rs-cc-color-light;
    margin: 0 0 20px;
    line-height: $rs-cc-line-height;
    font-size: 14px;
    letter-spacing: 0.78px;

    @media only screen and (min-width: 768px) {
      font-size: 16px;
      letter-spacing: 0.89px;
    }

    &.rs-cc__step-title {
      margin: 12px 0 24px;
      font-size: 22px;
      color: $colorBlack;
      letter-spacing: 0.5px;
      line-height: 24px;
      font-weight: 700;

      @media only screen and (min-width: 768px) {
        margin: 32px 0 30px;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.67px;
      }
    }

    &.rs-cc__step-segment {
      font-weight: bold;
      margin: 0 0 10px;
    }
  }

  &__step-description {
    justify-content: center;

    @include breakpoint(large) {
      padding-right: 40px;
      padding-top: 10px;
    }
  }

  /**
  * Buttons
  */

  &__buttons-container {
    margin-bottom: auto;
    margin-top: 12px;

    @include breakpoint(large) {
      margin-top: 4px;
    }
  }

  .button {
    &::before,
    &::after {
      display: none;
    }

    &--rs-cc {
      cursor: pointer;
      background-image: linear-gradient(180deg, #2b7cd7 0%, #2060b0 100%);
      box-shadow: 0 4px 10px 0 rgb(0 0 0 / 30%);
      border-radius: 4px;
      color: #fff;
      border: 0;
      padding: 10px;
      max-width: 100%;
      width: 100%;
      display: inline-block;
    }

    &.rs-cc__confirm-all {
      width: 120px;
    }

    &--link {
      border: none;
      text-decoration: underline;
      letter-spacing: 0.78px;
      line-height: 24px;
      background: none;
      color: $rs-cc-color-light;
      margin: 0;
      padding: 0;
      text-transform: none;
      font-family: $rs-cc-font-family;
      font-size: 14px;
      box-shadow: none;
      width: auto !important;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: $rs-cc-color-light !important;
        }
      }
    }

    &.-rounded {
      border-radius: 16px;
      text-transform: uppercase;

      @media (hover: hover) {
        &:hover {
          color: $rs-cc-color-light !important;
        }
      }
    }

    &.-underlined {
      text-decoration: underline;
      text-transform: none;
      border-color: transparent;
      padding: 0;
      margin: 0;
      font-size: 14px;
      border: none;
      display: inline-block;
      background: transparent;
      color: black;
      box-shadow: none;
      max-width: none;
      width: auto;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: $rs-cc-color-light !important;
        }
      }
    }

    &.rs-cc__save-settings {
      cursor: pointer;
      background-image: linear-gradient(180deg, #2b7cd7 0%, #2060b0 100%);
      box-shadow: 0 4px 10px 0 rgb(0 0 0 / 30%);
      border-radius: 4px;
      color: #fff;
      border: 0;
      padding: 10px 20px;
      width: auto;
      display: inline-block;
      text-transform: none;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: #fff !important;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 14px;
    padding: 0;
    margin: 0;
    border: 0;
    background: none !important;
    line-height: 0;
    width: 40px;
    height: 40px;
    text-align: center;

    .icon {
      fill: $colorBlack;
      font-size: 12px;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media only screen and (min-width: 768px) {
      margin-top: auto;
      top: 50%;
      transform: translateY(-50%);
      right: 28px;
    }
  }

  /**
  * Steps
  */

  &__step {
    overflow-y: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $rs-cc-color-background;
    color: $rs-cc-color-light;
    padding: 5px 0;
    border-top: 5px solid $colorBlue;

    &-2 {
      top: 0;

      .rs-cc__step-container {
        padding-bottom: 50px;
      }

      .rs-cc__close {
        top: 0;
        right: 0;
        transform: none;
      }

      @include breakpoint(medium) {
        top: 24px;
      }
    }

    @include breakpoint(medium) {
      top: auto !important;
      height: auto;
      max-height: 100%;
    }
  }

  /**
  * Settings
  */

  &__cookie-groups {
    margin-top: 30px;

    @media only screen and (min-width: 768px) {
      margin-top: 50px;
    }

    .group {
      position: relative;
      margin-bottom: 25px;

      @media only screen and (min-width: 768px) {
        margin-bottom: 30px;
      }

      &::before {
        content: none;
      }

      &:last-child {
        margin-bottom: 40px;

        @media only screen and (min-width: 768px) {
          margin-bottom: 50px;
        }
      }
    }
  }

  &__group-text {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 15px 0;
  }

  &__text-left {
    width: 42px;

    @media only screen and (min-width: 768px) {
      width: 60px;
    }
  }

  &__text-right {
    width: calc(100% - 42px);

    @media only screen and (min-width: 768px) {
      width: calc(100% - 60px);
    }
  }

  &__show-list-button,
  &__hide-list-button {
    position: absolute;
    right: 0;
  }

  &__group-description {
    font-size: 16px !important;
    letter-spacing: 0.32px !important;
    margin-bottom: 12px !important;
  }

  &__checkbox {
    appearance: checkbox;
  }

  /**
  * Cookies table
  */

  &__table-container {
    overflow-x: scroll;
    max-width: 768px;

    @media only screen and (min-width: 768px) {
      overflow-x: auto;
      max-width: none;
    }
  }

  &__group-table {
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    border-spacing: 0;
    border-collapse: collapse;

    @media only screen and (min-width: 768px) {
      min-width: none;
      width: 100%;
      max-width: 792px;
    }

    tr {
      border-bottom: 1px solid rgba(pink, 0.3);
    }

    th,
    td {
      padding: 7px 36px 9px 0;
      letter-spacing: 0.44px;
      text-align: left;
      border-top: 0;
      border-bottom: 1px solid $colorGrey;
      line-height: 24px;
      font-size: 15px;
      font-family: $rs-cc-font-family;
    }

    th {
      font-weight: 700;
    }
  }
}
