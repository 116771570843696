/* stylelint-disable no-descending-specificity */

.form-container {
  max-width: 650px;
  margin: 0 auto 100px auto;
  border-radius: 12px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.19);
  padding: 24px 0 32px 0;
  background-color: $colorWhite;

  @include breakpoint(large) {
    padding: 40px 0 48px 0;
  }

  &.download {
    position: relative;
    margin-top: 50px;
  }
}

input {
  border: 1px solid rgba($colorGreyDarker, 0.2);
  border-radius: 4px;
  background-image: linear-gradient(180deg, #f5fafd 0%, #fff 100%);
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.33);
  color: $colorBlack;
  padding-left: 17px;
  padding-right: 17px;
  height: 48px;
  display: block;
  width: 100%;

  &.error {
    border-color: $colorRed;
    box-shadow: none;

    &:focus {
      outline-color: $colorRed;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba($colorBlack, 0.5);
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba($colorBlack, 0.5);
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba($colorBlack, 0.5);
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba($colorBlack, 0.5);
  }

  &:focus-visible {
    outline: none;
  }
}

label {
  color: $colorBlack;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  display: block;
}

input[type="checkbox"] {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  ~ label {
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 0;
    padding-left: 36px;
    line-height: 19px;
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: 400;

    span {
      font-weight: 700;
      color: $colorBlue;

      img {
        margin-left: 3px;
        transform: rotate(0);
        transition: transform 0.25s ease;
      }

      &.active {
        img {
          transform: rotate(180deg);
        }
      }
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: block;
      border: 1px solid $colorGreyDark;
      background: $colorWhite;
      transition: all 0.25s ease;
      width: 24px;
      height: 24px;
      border-radius: 3px;
    }

    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 16px;
      background: url("../images/check.svg") 0 0 / 16px 16px no-repeat;
      left: 4px;
      top: 4px;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          background: $colorWhite;
          border-color: $colorBlue;
        }
      }
    }
  }

  &:focus {
    ~ label::before {
      border-color: $colorBlue;
    }
  }

  // Error
  .-has-error & {
    ~ label {
      &::before {
        background: $colorWhite;
        border-color: $colorRed;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            /* stylelint-disable-line max-nesting-depth */
            border-color: $colorRed;
          }
        }
      }
    }
  }

  &:checked {
    ~ label {
      &::before {
        border-color: $colorBlue;
        background: $colorWhite;
      }

      &::after {
        transition: width 0.25s ease;
        width: 16px;
      }
    }

    .-has-error & {
      ~ label {
        &::before {
          border-color: $colorBlue;
          background: $colorWhite;
        }

        &::after {
          transition: width 0.25s ease;
          width: 16px;
        }
      }
    }

    // Disabled
    &:disabled {
      ~ label {
        cursor: default;

        &::before {
          background: #eaeaea !important;
        }

        &::after {
          opacity: 0.5 !important;
        }
      }
    }
  }
}

button[type="submit"] {
  cursor: pointer;
}

.form-item {
  margin-bottom: 20px;

  &__error {
    margin: 0;
    padding: 0;
    color: $colorRed;
    font-size: 15px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blue-bg {
  background-color: $colorBlueLight2;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 24px;
  padding-bottom: 36px;

  .white {
    background-color: $colorWhite;
    padding: 24px;

    @include breakpoint(large) {
      padding: 32px;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      margin-top: 0;
    }

    .form-item__error {
      margin-top: 10px;
    }
  }
}

.form-item--radio {
  display: block;
  position: relative;
  margin-bottom: 5px !important;

  input[type="radio"] {
    display: none;

    &:checked + label::after {
      border-radius: 11px;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 9px;
      left: 4px;
      content: " ";
      display: block;
      background: $colorBlue;
    }
  }

  label {
    margin-bottom: 0;
    display: flex;
    align-items: baseline;

    &::before {
      content: " ";
      display: inline-block;
      position: relative;
      top: 5px;
      margin: 0 5px 0 0;
      width: 20px;
      height: 20px;
      border: 1px solid $colorRadioBorder;
      background: linear-gradient(180deg, $colorRadio 0%, $colorWhite 100%);
      box-shadow: inset 0 0 4px 0 $colorRadioShadow;
      border-radius: 50%;
    }

    span {
      margin-left: 10px;
      display: inline-block;
      width: 200px;
      letter-spacing: -0.31px;
      line-height: 18px;

      @include breakpoint(large) {
        width: auto;
      }
    }
  }
}
